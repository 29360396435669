export default {
  title: 'Impressum',
  meta: 'empto<sup>&reg;</sup> ist ein Produkt der Zentek Services GmbH & Co. KG',
  text: `
    Zentek Services Verwaltungs GmbH als persönlich haftende Gesellschafterin vertreten durch die Geschäftsführer
    <br />
    Dipl.-Kaufmann Christoph Ley <br />
    Dipl.-Betriebswirt Olaf Pusch <br />
    Ettore-Bugatti-Str. 6-14<br />
    51149 Köln<br />
    <br />
    Telefon: 02203 8987-0 <br />
    Fax: 02203 8987-999 <br />
    E-Mail: info@zentek.de <br />
    <br />
    Handelsregister: Amtsgericht Köln <br />
    Registernummer: HRA 33824 <br />
    USt.IdNr: DE 321 853 018 <br />
    `,
  certification: `
    <b>Zertifizierungsstelle:</b> <br/> <br/>
    ZER-QMS Zertifizierungsstelle Qualitäts- und Umweltgutachter GmbH <br/>
      <a href='https://www.zer-qms.de/' target='_blank' rel='noopener noreferrer'>https://www.zer-qms.de/</a>
    `,
}
